<template>
    <section>
      <custom-breadcrumb
        :pageTitle="$t('Virtual Catalog')+' - '+$t('Learning Paths') "
        :breadcrumb="[]"
      ></custom-breadcrumb>
      <b-row v-if="loading">
        <b-col cols="12"  >
          <div class="text-center">
            <b-spinner label="Loading..." />
          </div>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col cols="12" >
          <learning-path-list 
            :isCatalog="true"
            @refetch-data="getData"
          />
        </b-col>
      </b-row>
    </section>
</template>

<script>
import LearningPathList from '@/views/learning-paths/LearningPathList.vue'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'
import { 
  //getAllLearningPaths,
  getContents,
  getHierarchy,
  getLearningPath
 } from '@/api/learningpaths'
import {   
  BSpinner, BRow, BCol 
} from 'bootstrap-vue';
export default {
    components:{
        BSpinner, BRow, BCol,
        LearningPathList,
        CustomBreadcrumb
    },
    data(){
        return {
            loading: true
        }
    },
    created(){
      this.getData()
    },
    computed:{
      lang(){
        return this.$store.getters['user/getLang']
      }
    },
    watch:{
      lang(newLang, oldLang){  /* lanzar la llamada cada vez que cambie el idioma del usuario! */
        this.getData()
      }
    },
    methods:{
      getData(){
        this.loading = true
        let data = {
          'user_id':  this.$store.getters['user/getUserData'].id,
          'lang': this.$store.getters['user/getLang'],
          'token': this.$store.getters['user/getUserData'].sso,
        }
        const promises = [
          this.getDataContents(data),
          this.getDataHierarchy(data),
          this.getDataLPs(data),
        ];

        Promise.allSettled(promises).then((results) =>
          this.loading = false
        );
      },
      getDataContents(data){
        return new Promise((resolve, reject) => {
          this.$http.get(getContents, {params: data}).then( response => {
            this.$store.commit('learningPaths/setContents', response.data)
            return resolve(true)
          }).catch(error => {
            // console.log("Err -> ", error);
            this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
            return reject(error)
          })
        })
      },
      getDataHierarchy(data){
        return new Promise((resolve, reject) => {
          this.$http.get(getHierarchy, {params: data}).then( response => {
            // Para el catálogo, sólo mostramos las rutas creadas por smile
            let myHierarchy = [
              { "source":"smileandlearn", "subcategories": response.data } 
            ]
            this.$store.commit('learningPaths/setHierarchy', myHierarchy )
            return resolve(true)
          }).catch(error => {
            // console.log("Err -> ", error);
            this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
            return reject(error)
          })
        })
      },
      getDataLPs(data){
        return new Promise((resolve, reject) => {
          this.$http.get(getLearningPath, {params: data}).then( response => {
            this.$store.commit('learningPaths/setLearningPaths', response.data)
            return resolve(true)
          }).catch(error => {
            // console.log("Err -> ", error);
            this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
            return reject(error)
          })
        })
      },
      makeToast(variant = null, tit ='',msg = '') {
        this.$bvToast.toast(msg, {
          title: tit,
          variant,
          solid: false,
        })
      },

    }
}
</script>